// import {OrbitControls} from '@react-three/drei'
// import {Perf} from 'r3f-perf'

export default function Debug () {
    return (
        <>
            {/* <Perf position={'top-right'} />
            <OrbitControls
                makeDefault
                minPolarAngle={Math.PI * 0.4}
                maxPolarAngle={Math.PI * 0.5}
            /> */}
        </>
    )
}